// const i18n = require("../../config/i18n")
import browserLang from "browser-lang"
import { navigate } from "gatsby"

const locales = require("../../config/i18n")

export function redirectToUserLanguage() {
  if (typeof window !== "undefined") {
    const browserUserLang = browserLang()
      .toLowerCase()
      .slice(0, 2)
    // if user doesn't have a lang defined in localstorage yet
    if (!window.sessionStorage.getItem("userPreferedLang")) {
      Object.keys(locales).map(lang => {
        const localeLang = lang.slice(0, 2)
        let defaultAppLang = undefined
        window.sessionStorage.setItem("userPreferedLang", browserUserLang)

        if (locales[lang].default === true) {
          defaultAppLang = locales[lang].locale.slice(0, 2)
        }

        // if browserLang is available in locales -> redirect
        if (localeLang === browserUserLang) {
          // if equals default redirect to "/" (/defaultLang do not exist)
          navigate("/" + locales[lang].path + "/")
        }
        return true
      })
      // browserUserLang do not match any known lang, redirect to default
      // navigate("/")
    }
    else{
      const userPreferedLang = window.sessionStorage.getItem("userPreferedLang")
      Object.values(locales).map(lang => {
        const localeLang = lang.siteLanguage;
        if(localeLang === userPreferedLang){
          navigate("/" + lang.path + "/");
        }
      });
    }
  }
}

export function isExternalLink(link) {
  if (link === null) return false
  return link._linkType === "Link.web" ? true : false
}
export function isDocumentLink(link) {
  if (link === null) return false
  return link._linkType === "Link.document" ? true : false
}

export const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
