module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Monstock - Manage your inventory on the go", // Navigation and Site Title
  titleAlt: "Monstock - Manage your inventory on the go", // Title for JSONLD
  description:
    "Mobile inventory and flow management software with full traceability",
  headline: "Monstock", // Headline for schema.org JSONLD
  url: `${process.env.URL}`, // Domain of your site. No trailing slash!
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "static/images/favicon.png", // Used for manifest favicon generation
  shortName: "Monstock", // shortname for manifest. MUST be shorter than 12 characters
  author: "Monstock", // Author for schemaORGJSONLD
  themeColor: "#fa6400",
  backgroundColor: "#ffffff",
  logo: "/images/logo.png",

  twitter: "@monstock_net", // Twitter Username
  facebook: "monstock", // Facebook Site Name
  googleAnalyticsID: "UA-92829588-2",

  gdprMessage: "This website uses cookies to enhance the user experience",
}
