import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import LocalizedLink from "../components/localizedLink"
import { LocaleContext } from "./layout"
import locales from "../../config/i18n"
import LangSwitcher from "../components/langSwitcher"
import linkResolver from "../utils/linkResolver"
import { openPopupWidget } from "react-calendly"

import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import i18n from "../../config/i18n"


//Varible important for the gestion of window during gatsby build use for google Analytics
const dataLayerw = typeof window !== `undefined` ? (window.dataLayer = window.dataLayer || []) : null

const CalendlyButton = ({ url, prefill, pageSettings, utm, children }) => {
const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })

  return <span onClick={onClick}>{children}</span>
}
export default function Header({ allHeadersAndFooters }) {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const [subMenuOpened, setSubMenuOpened] = useState(false)
  let { locale, location, alternateLanguages } = React.useContext(LocaleContext)
  locale = locale ? locale : "en-gb" // fix: 404 doesnt know the locale
  const pathToHome =
    locales[locale].default === true ? "/" : `/${locales[locale].path}/`
  if (allHeadersAndFooters === undefined) return false // fix bug previews/ doesn't know the header
  if (typeof allHeadersAndFooters === "string") return false // fix bug previews/ doesn't know the header
  const headerData = allHeadersAndFooters?.prismic?.allHeaders.edges
  let headerDataLang = headerData.filter(
    headerData => headerData.node._meta.lang === locale
  )
  headerDataLang = headerDataLang[0].node
  const isHomepage =
    location.pathname === "/" ||
    location.pathname === `/${i18n[locale].path}` ||
    location.pathname === `/${i18n[locale].path}/`
      ? true
      : false

  const navClassName = isHomepage
    ? "navbar nav-background-transparent"
    : "navbar nav-background-blue"

  return (
    <header css={headerCss}>
      <head>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-92829588-2"></script>
        <script>
          {
            //Script for google Analytics Tracking Button "Essaie Gratuit"
            function gtag(){
              dataLayerw.push(arguments)
            },
            function scriptrun() {
              if(dataLayerw === null) {
                return (null)
              }
              else {
                gtag('js', new Date()),
                gtag('config', 'UA-92829588-2')
              }
              scriptrun()
            }      
          }
        </script>
      </head>
      <div className="ticker-wrap">
        <div className="ticker">
          <div className="ticker__item notification mb-0">
            {/* <button aria-label="close alert" className="delete"></button> */}
            {RichText.render(headerDataLang.alert_message, linkResolver)}
          </div>
        </div>
      </div>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            {headerDataLang.header_call_to_action.map(action => {
              // if(action.header_call_to_action_id ==='appointment'){ return <CustomButton url={"https://calendly.com/monstock"} />}
              if (action.header_call_to_action_id === "appointment") {
                return (
                  <CalendlyButton
                    key={action.header_call_to_action_id}
                    url={`https://calendly.com/monstock/${
                      locale === "fr-fr" ? "rdv" : "book"
                    }`}
                  >
                    <div
                      className="header__cta has-text-white pr-3"
                      css={{ cursor: "pointer" }}
                    >
                      <span
                        className={
                          "has-background-white icon-" +
                          action.header_call_to_action_id.toLowerCase()
                        }
                      />
                      {action.header_call_to_action_name}
                    </div>
                  </CalendlyButton>
                )
              }
              if (action.header_call_to_action_id === "phone") {
                return (
                  <a
                    key={action.header_call_to_action_id}
                    href={`tel:${action.header_call_to_action_name}`}
                    className="header__cta has-text-white pr-3"
                  >
                    <span
                      className={
                        "has-background-white icon-" +
                        action.header_call_to_action_id.toLowerCase()
                      }
                    />
                    {action.header_call_to_action_name}
                  </a>
                )
              }
              if (action.header_call_to_action_id === "email") {
                return (
                  <a
                    key={action.header_call_to_action_id}
                    href={`mailto:${action.header_call_to_action_name}`}
                    className="header__cta has-text-white pr-3"
                  >
                    <span
                      className={
                        "has-background-white icon-" +
                        action.header_call_to_action_id.toLowerCase()
                      }
                    />
                    {action.header_call_to_action_name}
                  </a>
                )
              }
            })}
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <p className="has-text-white">
              {RichText.asText(headerDataLang.header_social_title)}
            </p>
            {headerDataLang.header_social.map(social => {
              return (
                <a
                  key={social.header_social_id}
                  href={social.header_social_link.url}
                  className="header__social has-text-white"
                  target="_blank"
                >
                  <span
                    className={"icon-" + social.header_social_id.toLowerCase()}
                  />
                  <span className="is-hidden">{social.header_social_id}</span>
                </a>
              )
            })}
          </div>
        </div>
      </nav>

      <nav
        className={navClassName}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to={pathToHome}>
            <div className="logo">
              <Image
                sharp={headerDataLang.header_logo_imageSharp}
                image={headerDataLang.header_logo_image}
              />
            </div>
          </Link>
          <div
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="menu"
            onClick={() => {
              setIsOpenMobileMenu(!isOpenMobileMenu)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          id="menu"
          className="navbar-menu"
          css={{ display: isOpenMobileMenu ? "block" : "" }}
        >
          <div className="navbar-start">
            {headerDataLang.body.map(item => {
              if (item.type === "menu_element") {
                // simple menu element
                let menuItemClass = "navbar-item"
                if (item.primary.menu_element_id === "connexion") {
                  // if this is connexion item give bold class
                  menuItemClass = "button is-rounded is-outlined is-link is-connexion"
                  return (
                    <a
                      key={item.primary.menu_element_id}
                      className={menuItemClass}
                      href={item.primary.menu_element_link.url}
                      onClick={() => setIsOpenMobileMenu(false)}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {item.primary.menu_element_name}
                    </a>
                  )
                }
                if (item.primary.menu_element_id === "freetrial") {
                  // if this is free trial give btn primary class
                  menuItemClass =
                    "button is-rounded button__primary has-text-white"
                }
                const link = item.primary.menu_element_link
                if (link?._linkType === "Link.document") {
                  return (
                    <LocalizedLink
                      key={item.primary.menu_element_id}
                      to={link._meta.uid}
                      className={menuItemClass}
                      onClick={() => setIsOpenMobileMenu(false)}
                    >
                      {item.primary.menu_element_name}
                    </LocalizedLink>
                  )
                }
                if (link?._linkType === "Link.web")
                  //button a tracker
                  if (link.url = "https://cloud.monstock.net/#/register") {
                    return (
                      <a
                        key={item.primary.menu_element_id}
                        className={menuItemClass}
                        href={link.url}
                        onClick={() => (
                          setIsOpenMobileMenu(false),
                          // console.log(dataLayerw),
                          gtag('event', 'Clic', {'event_category': 'Essai_gratuit','event_label': window.location.pathname}),
                          // gtag({'event':'Free_Trial','': '','event_category': 'Essai_gratuit','event_label': window.location.pathname}),
                          console.log(window.location.pathname,dataLayerw)
                        )}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        
                      >
                        {item.primary.menu_element_name}
                        </a>
                    )
                  }
                  return (
                    <a
                      key={item.primary.menu_element_id}
                      className={menuItemClass}
                      href={link.url}
                      onClick={() => setIsOpenMobileMenu(false)}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {item.primary.menu_element_name}
                    </a>
                  )
              } else if (item.type === "menu_element_with_subelements") {
                // menu element with subelements
                return (
                  <div
                    key={item.primary.menu_group_id}
                    className="navbar-item has-dropdown is-hoverable has-text-weight-semibold"
                  >
                    <a
                      className="navbar-link"
                      onClick={() => {
                        return !isOpenMobileMenu
                          ? false // click only works for mobile
                          : subMenuOpened === item.primary.menu_group_id
                          ? setSubMenuOpened(false) // submenu already opened? close it
                          : setSubMenuOpened(item.primary.menu_group_id)
                      }}
                    >
                      {item.primary.menu_group_name}
                    </a>

                    <div
                      className={`navbar-dropdown ${
                        isOpenMobileMenu ? "navbar__dropdown-mobile" : ""
                      }`}
                      css={{
                        display:
                          subMenuOpened === item.primary.menu_group_id
                            ? "block"
                            : "none",
                      }}
                    >
                      {item.fields.map(element => {
                        if (
                          element.menu_group_element_link?._linkType ===
                          "Link.document"
                        ) {
                          return (
                            <LocalizedLink
                              key={element.menu_group_element_id}
                              to={element.menu_group_element_link._meta.uid}
                              className="navbar-item"
                              onClick={() => setIsOpenMobileMenu(false)}
                            >
                              {element.menu_group_element_name}
                            </LocalizedLink>
                          )
                        }
                        if (
                          element.menu_group_element_link?._linkType ===
                          "Link.web"
                        )
                          return (
                            <a
                              key={element.menu_group_element_id}
                              className="navbar-item"
                              href={element.menu_group_element_link.url}
                              onClick={() => setIsOpenMobileMenu(false)}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              {element.menu_group_element_name}
                            </a>
                          )
                        return true
                      })}
                    </div>
                  </div>
                )
              }
              return true
            })}
          </div>

          <div className="navbar-end">
            <LangSwitcher flags={allHeadersAndFooters.allFile} />
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  allHeadersAndFooters: PropTypes.object.isRequired,
}

const headerCss = css`
  .dropdown__lang {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
    .button {
      &.button {
        border: 1px solid ${colors.blue};
        padding-right: 20px;
      }
      span {
        display: none;
      }
      .icon {
        padding-left: 0;
      }
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    background-color: #000;
    padding-left: 100%;
    box-sizing: content-box;

    .notification {
      border-radius: 0;
    }

    .ticker {
      display: inline-block;
      height: 3rem;
      line-height: 3rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;

      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 20s;
      animation-duration: 20s;

      &__item {
        background-color: #000;
        display: inline-block;
        padding: 0 2rem;
        color: white;
      }
    }
  }

  .navbar {
    padding: 20px;

    .logo {
      width: 180px;
    }

    .navbar-burger:hover {
      background-color: ${colors.blueLightOne};
      border-radius: 9px;
    }

    .gatsby-image-wrapper {
      display: flex;
    }

    .navbar-end {
      align-items: center;
    }

    .is-connexion {
      margin-right: 30px;
    }

    .navbar-item {
      .navbar-link {
        &:hover,
        &:focus,
        &:focus-within,
        &:active {
          color: #FFF;
        }
      }

      &:hover {
        background-image: ${colors.orangeGradient};
        border-radius: 17px;
        color: #FFF;

        &:focus {
          display: none;
        }

        .navbar-link {
          color: #fff;
        }
      }
    }
  }

  .navbar.nav-background-blue {
    background-color: ${colors.blueBg};
  }

  .navbar.nav-background-transparent {
    background-color: transparent;
    position: absolute;
    width: 100%;
  }

  .navbar-dropdown {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    border-top: 0px;
    padding: 10px;

    &.navbar__dropdown-mobile {
      left: 0;
      transform: none;
      background: ${colors.zircon};

      .navbar-item:hover {
        background-image: none;
        color: ${colors.orange};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .navbar-item:not(:last-child):hover {
        border-bottom-color: ${colors.blueLightOne};
      }
      &::after {
        left: 30px;
        border-bottom-color: ${colors.zircon};
        margin-top: 27px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }

    .navbar-item {
      border-bottom: 1px solid ${colors.blueLightOne};

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      &:hover,
      &:focus {
        background-image: ${colors.orangeGradient};
        color: #fff;
        border-radius: 7px;
        border-bottom-color: transparent;
        border-top-color: transparent;
      }
    }
  }

  .level {
    margin-bottom: 0;
    padding: 1.5rem 3rem;
    background-color: ${colors.blue};

    .level-left a {
      padding-right: 30px;
    }

    .level-right p {
      height: 27px;
      display: flex;
      align-items: center;
    }
  }

  .navbar {
    flex-wrap: wrap;

    &.is-transparent {
      background-color: transparent;
      background-image: none;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: ${colors.blue};
  }

  .navbar-start {
    align-items: center;
  }

  .navbar-menu a {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .header__cta {
    display: flex;
    span {
      color: ${colors.blue};
      height: 27px;
      width: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .header__social {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    width: 27px;
    height: 27px;
    border: solid #fff 2px;
    border-radius: 50%;
    margin: 0 5px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .level-left {
      .level-item {
        align-items: baseline;
      }
    }
  }

  @media (max-width: 1023px) {
    .navbar-brand a {
      display: flex;
      align-items: center;
    }

    .navbar-brand {
      align-items: center;
    }
    .level {
      padding: 1.5rem;
    }
    .level-left {
      .level-item {
        flex-direction: column;
      }
      .header__cta {
        margin-bottom: 10px;
      }
    }

    .navbar-end,
    .navbar-start .button {
      margin: 10px;
    }

    .navbar-menu {
      border-radius: 10px;
    }

    .dropdown__lang {
      .dropdown-menu {
        left: 0;
        right: auto;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .navbar-menu a {
      font-size: 0.75rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .navbar-menu {
      flex-grow: 0;
      flex-shrink: 1;
      flex-wrap: wrap;
    }
    .navbar,
    .navbar-menu,
    .navbar-start,
    .navbar-end {
      align-items: center;
      display: flex;
    }
    .navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .navbar-brand,
    .navbar-item,
    .navbar-start {
      margin-right: 30px;
    }
    .navbar-dropdown {
      .navbar-item {
        margin-right: 0;
      }
    }
  }
`
