import React from "react"
import { RichText } from "prismic-reactjs"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import { LocaleContext } from "./layout"
import LocalizedLink from "../components/localizedLink"
import LangSwitcher from "../components/langSwitcher"
import linkResolver from "../utils/linkResolver"
import Image from "../components/image"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import CookieConsent from "react-cookie-consent"
import i18n from "../../config/i18n"

export default function Footer({ allHeadersAndFooters }) {
  let { locale, location } = React.useContext(LocaleContext)
  if (allHeadersAndFooters === undefined) return false // fix bug previews/ doesn't know the footer
  if (typeof allHeadersAndFooters === "string") return false // fix bug previews/ doesn't know the footer
  locale = locale ? locale : "en-gb" // fix: 404 doesnt know the locale
  let footerDataLang = allHeadersAndFooters.prismic.allFooters.edges.filter(
    footerData => footerData.node._meta.lang === locale
  )
  footerDataLang = footerDataLang[0].node

  const isHomepage =
    location.pathname === "/" || location.pathname === `/${locale.slice(0, 2)}/`
      ? true
      : false

  return (
    <>
      <footer
        className="footer"
        css={footerCss}
        style={isHomepage ? { backgroundColor: colors.blueBg } : {}}
      >
        <div className="container">
          <div className="content">
            <div className="columns">
              {footerDataLang.body.map(category => (
                <div key={category.primary.category_id} className="column">
                  <nav>
                    <h6 className="title">
                      {RichText.asText(category.primary.category_name)}
                    </h6>
                    <ul>
                      {category.fields.map(link => (
                        <li key={link.footer_element_id}>
                          {link.footer_element_link &&
                            link.footer_element_id !== "contactus" &&
                            isExternalLink(link.footer_element_link) && (
                              <a
                                href={link.footer_element_link.url}
                                target={link.footer_element_link.target}
                                rel="noopener noreferrer nofollow"
                              >
                                {link.footer_element_name}
                              </a>
                            )}
                          {link.footer_element_link &&
                            link.footer_element_id !== "contactus" &&
                            isDocumentLink(link.footer_element_link) && (
                              <LocalizedLink
                                to={link.footer_element_link._meta.uid}
                              >
                                {link.footer_element_name}
                              </LocalizedLink>
                            )}
                          {link.footer_element_id === "contactus" && (
                            <a
                              href={`mailto:contact@monstock.net?subject=Contact Via Footer`}
                            >
                              {link.footer_element_name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              ))}
              <div className="column">
                <h6 className="title">
                  {RichText.asText(footerDataLang.column_title)}
                </h6>
                {footerDataLang.link_app_1 && (
                  <a
                    href={footerDataLang.link_app_1.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <figure className="image is-128x128 is-inline-block ml-0">
                      {footerDataLang.image_app_1 && (
                        <Image
                          sharp={footerDataLang.image_app_1Sharp}
                          image={footerDataLang.image_app_1}
                        />
                      )}
                    </figure>
                  </a>
                )}
                {footerDataLang.link_app_2 && (
                  <a
                    href={footerDataLang.link_app_2.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <figure className="image is-128x128 is-inline-block ml-0">
                      {footerDataLang.image_app_2 && (
                        <Image
                          sharp={footerDataLang.image_app_2Sharp}
                          image={footerDataLang.image_app_2}
                        />
                      )}
                    </figure>
                  </a>
                )}
                <LangSwitcher flags={allHeadersAndFooters.allFile} />
              </div>
            </div>

            <div className="columns is-centered">
              <div className="column hasRichText has-text-centered">
                <hr />
                {footerDataLang.copyright &&
                  RichText.render(footerDataLang.copyright, linkResolver)}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div css={gdpr}>
        <CookieConsent
          location="bottom"
          enableDeclineButton
          buttonText={
            i18n[locale].gdprAccept
              ? i18n[locale].gdprAccept
              : i18n["en-gb"].gdprAccept
          }
          declineButtonText={
            i18n[locale].gdprDecline
              ? i18n[locale].gdprDecline
              : i18n["en-gb"].gdprDecline
          }
          cookieName="gdpr-cookies"
          disableStyles={true}
          containerClasses="gdpr"
          contentClasses="gdprContent"
          buttonClasses="button is-small is-rounded button__primary has-text-white mt-2"
          declineButtonClasses="button is-small is-rounded is-outlined is-link mt-2 mr-2"
        >
          {i18n[locale].gdprMessage
            ? i18n[locale].gdprMessage
            : i18n["en-gb"].gdprMessage}
        </CookieConsent>
      </div>
    </>
  )
}

const footerCss = css`
  .footer__blue {
    background-color: ${colors.blueBg};
  }

  h6 {
    font-size: 1.125rem;
  }

  hr {
    background-color: ${colors.blueHr};
    height: 1px;
  }

  .content ul {
    font-size: 14px;
    margin-left: 0;
    line-height: 33px;
    list-style: none;

    a {
      color: ${colors.blue};
    }
  }

  .is-128x128 {
    height: 60px;
  }

  .dropdown-menu {
    max-height: 130px;
    overflow: scroll;
  }
`
const gdpr = css`
  .gdpr {
    position: fixed;
    bottom: 0;
    background: white;
    max-width: 300px;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    z-index: 100;
    box-shadow: 0 12px 14px ${colors.blueLightOne};
  }
`
