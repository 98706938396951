import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { LocaleContext } from "./layout"
import locales from "../../config/i18n"

const LocalizedLink = ({ to, ...props }) => {
  let { locale } = React.useContext(LocaleContext)
  locale = locale ? locale : "en-gb" // fix 404

  const isIndex = to === "/" || to === "homepage"
  const path =
    locales[locale].default === true
      ? to
      : `${locales[locale].path}/${isIndex ? "" : `${to}`}`

  return <Link {...props} to={`/${path}`} />
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}
