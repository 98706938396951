const website = require("./website")

module.exports = {
  "en-gb": {
    default: false,
    path: "en_gb",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_US",
    langName: "English",
    defaultTitle: website.title,
    defaultDescription: website.description,
    headline: website.headline,
    gdprMessage: website.gdprMessage,
    gdprAccept: "Accept",
    gdprDecline: "Decline",
  },
  "fr-fr": {
    default: false,
    path: "fr_fr",
    locale: "fr-fr",
    langName: "Français",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    defaultTitle: "Monstock - Gérez vos stocks en toute mobilité",
    defaultDescription:
      "Logiciel de gestion des stocks et des flux en toute mobilité avec traçabilité complète",
    headline: website.headline,
    gdprMessage: "Pouvons-nous utiliser des cookies?",
    gdprAccept: "Accepter",
    gdprDecline: "Refuser",
  },
  "es-es": {
    default: false,
    path: "es_es",
    locale: "es-es",
    langName: "Español",
    siteLanguage: "es",
    ogLang: "es_ES",
    defaultTitle: " Monstock - gestionar sus acciones sobre la marcha",
    defaultDescription:
      "Inventario y gestión de flujo de software móvil con una trazabilidad completa",
    gdprMessage: " Podemos utilizar cookies?",
    gdprAccept: "Aceptar",
    gdprDecline: " Disminución",
  },
  "de-de": {
    default: false,
    path: "de_de",
    locale: "de-de",
    langName: "Deutsche",
    siteLanguage: "de",
    ogLang: "de_DE",
    defaultTitle: "Monstock - Verwalten Sie Ihre Bestände auf dem Sprung",
    defaultDescription:
      "Mobile Inventur und Flow-Management-Software mit voller Rückverfolgbarkeit",
    gdprMessage: "Können wir Cookies verwenden?",
    gdprAccept: "Akzeptieren",
    gdprDecline: "Ablehnen",
  },
  "pt-pt": {
    default: false,
    path: "pt_pt",
    locale: "pt-pt",
    langName: "Português",
    siteLanguage: "pt",
    ogLang: "pt_PT",
    defaultTitle: "Monstock - Gerencie seus stocks em movimento",
    defaultDescription:
      "Programas de inventário e gestão do fluxo móvel com total rastreabilidade",
    gdprMessage: "Podemos usar cookies?",
    gdprAccept: "Aceitar",
    gdprDecline: "Declínio",
  },

  "ru-ru": {
    default: false,
    path: "ru_ru",
    locale: "ru-ru",
    langName: "русский",
    siteLanguage: "ru",
    ogLang: "ru_RU",
    defaultTitle: "Monstock - Управление запасов на ходу",
    defaultDescription:
      "Мобильный инвентаризации и управления потоком данных с полной прослеживаемости",
    gdprMessage: "Можно ли использовать куки?",
    gdprAccept: "принимать",
    gdprDecline: "снижение",
  },

  "zh-cn": {
    default: false,
    path: "zh_cn",
    locale: "zh-cn",
    langName: "中文",
    siteLanguage: "zh",
    ogLang: "zh_CN",
    defaultTitle: "Monstock - 随时随地管理您的股票",
    defaultDescription: "移动广告和流量管理软件全程追溯",
    gdprMessage: "我们可以用饼干吗？",
    gdprAccept: "接受",
    gdprDecline: "下降",
  },

  "it-it": {
    default: false,
    path: "it_it",
    locale: "it-it",
    langName: "Italiano",
    siteLanguage: "it",
    ogLang: "it_IT",
    defaultTitle: "Monstock - Gestisci le tue azioni in movimento",
    defaultDescription:
      "Software di inventario e la gestione dei flussi di mobile con piena tracciabilità",
    gdprMessage: "Possiamo usare i cookie?",
    gdprAccept: "Accettare",
    gdprDecline: "Declino",
  },

  "ja-jp": {
    default: false,
    path: "ja_jp",
    locale: "ja-jp",
    langName: "日本人",
    siteLanguage: "ja",
    ogLang: "ja_JP",
    defaultTitle: "Monstock - 外出先での在庫を管理します",
    defaultDescription:
      "完全なトレーサビリティとモバイル在庫およびフロー管理ソフトウェア",
    gdprMessage: "私たちはクッキーを使用することはできますか？",
    gdprAccept: "受け入れます",
    gdprDecline: "低下",
  },
}
